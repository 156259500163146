.isolated-v1_5 {
  @import "../shared/_defaults";
  @include display-header;
  @include origin-flag-tagboard-classic;

  .posts {
    top: 16vmin;
    left: 5%;
    width: 90%;
    height: calc(100% - 21vmin);
  }

  .post {
    font-size: 5vmin;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
    color: $dark-gray;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .media-module {
    position: relative;
    width: 50%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: relative;
    width: 100%;
    height: 100%;
    float: right;
    padding: .5em;
    background-color: $white;
    overflow: hidden;
  }

  .has-media .text-module {
    width: 50%;
    height: 100%;
  }

  .portrait-post {
    .blurred-media-module {
      width: 100%;
      height: 60%;
    }

    .media-module {
      float: none;
      width: 100%;
      height: 60%;
    }

    .text-module {
      width: 100%;
      height: 100%;
    }

    &.has-media .text-module {
      float: none;
      width: 100%;
      height: 40%;
    }
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1em;
    margin-bottom: .5em;
  }

  .twitch {

    .badges img, .emote img{
      width: 1em;
      margin-right: 5px;
    }

    .emote img {
      width: .8em;
      margin-right: 1vmin;
    }

    .username{
      font-weight: bold;

      &:after{
        content: ":";
      }
    }
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }

    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {
    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .reddit .subreddit {
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 1.3;
    @include ellipsis;

    + .handle {
      font-size: .8em;
      line-height: 1.3;
    }
  }

  .twitch.has-media,
  .heymarket.has-media{
    .text-module {
      display: none;
    }

    .media-module {
      float: none;
      width: 100%;
      height: 100%;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }

  .audio-attribution {
    position: absolute;
    width: 100%;
    bottom: 0%;
    left: 0%;
    color: #fff;
    padding: 0.5em 1em;
    font-size: .5em;
    line-height: 1.75;
    text-shadow: 0px 0px 0.3em #000;
  }

  .audio-avatar {
    display: inline-block;
    width: 1.75em;
    height: 1.75em;
    margin-left: .15em;
    margin-bottom: -.3em;
    border-radius: 100%;
    background-color: transparent;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    animation: spin 8s linear infinite;

    @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .audio-info {
    width: calc(50% - 2em);
    display: inline-block;
    margin-left: .15em;
    margin-bottom: -.3em;
    overflow: hidden;
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 8%, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 8%, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
  }

  .audio-info-inner {
    display: inline-block;
    white-space: nowrap;
    transform: translateX(100%);
    animation: marquee 8x linear infinite;
    -webkit-animation: marquee 8s linear infinite;

    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-33.333333%);
      }
    }

    @-webkit-keyframes marquee {
      0% {
        -webkit-transform: translateX(0%);
      }
      100% {
        -webkit-transform: translateX(-33.333333%);
      }
    }
  }

  .post-title {
    margin-bottom: .5em;
    font-weight: bold;
  }

  .origin-flag {
    top: .5em;
    right: -.3em;
  }

  // CUSTOMIZATIONS

  .post-height-auto {
    .post {
      position: absolute;
      top: 50%;
      height: auto;
      min-height: 50%;
      max-height: 100%;
    }

    .text-module {
      max-height: 100%;
    }

    .has-media .text-module {
      position: absolute;
      top: 0;
      right: 0;
    }

    .media-module > div {
      width: 100%;
      height: 100%;

      &.audio-attribution {
        height: auto;
      }
    }

    .media {
      position: relative;
    }
  }

  .rounded-post-corners {
    .post {
      border-radius: .25em;
    }

    .media-module {
      border-radius: .25em 0 0 .25em;
    }

    .text .text-module, .chat .text-module {
      border-radius: .25em;
    }

    .has-media .text-module {
      border-radius: 0 .25em .25em 0;
    }
  }

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .text-module {
      background-color: rgba($black, .5);
    }
  }
  .share-it.no-text {
    .media-module {
      float: none;
      margin: 0 auto;
      height: 100%;
    }

    .blurred-media-module {
      width: 100%;
      display: block;
    }
  }

  // QR CODE
  .has-qr-code {
    &.qr-right {
      .display-wrapper {
        flex-direction: row-reverse;
      }

      .posts{
        justify-content: end;
      }
    }
    .display-wrapper {
      display: flex;
    }
    .posts {
      position: relative;
      height: calc(100% - 35vmin);
      width: 100%;
      top: auto;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: start;
      margin: auto;

      .post {
        width:calc(100% - 10vmin);
        font-size: 4.5vmin;
      }
    }
    .qr-module {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      flex-basis: 30%;
      padding: 0 8vmin;
    }

    .square-placeholder {
      width: auto;
      height: 100%;
      display: none;
    }
  }
  //QR Header Templates
  .cta-template-1 {
    .display-header .qr-module {
      width: 8.5%;
      left:.75%
    }
  }
  .cta-template-2 {
    .display-header .qr-module {
      width: 8.5%;
    }
  }
    .cta-template-3 {
      .display-header .qr-module {
        width: 8.5%;
        left: .75%;
      }
    }
      .cta-template-4 {
        .display-header .qr-module {
          width: 8.5%;
      }
    }
  }